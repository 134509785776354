<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header-inner">
        <form
          @submit.prevent="(page = 0), getFrequentlyBought()"
          @keydown.enter="(page = 0), getFrequentlyBought()"
        >
          <div class="filter-card">
            <div class="filter-field">
              <label>商品查詢</label>
              <input type="text" placeholder="請輸入關鍵字" v-model.trim="keyword" />
            </div>
            <button type="submit" class="btn btn-danger btn-sm ml-1">
              查詢
            </button>
          </div>
        </form>

        <div class="checkbox-wrap">
          <template v-for="(item, index) in originalCategory">
            <label :key="index" v-if="item.iS_ACTIVE">
              <input type="radio" :value="item.categorY_ID" v-model="searchCategory" />
              <span
                class="text"
                @click="(searchCategory = item.categorY_ID), getFrequentlyBought()"
              >
                {{ item.categorY_NAME }}
              </span>
            </label>
          </template>

          <label>
            <input type="radio" value="" v-model="searchCategory" />
            <span class="text" @click="(searchCategory = ''), getFrequentlyBought()">全部</span>
          </label>

          <button
            type="button"
            class="set-category"
            data-toggle="modal"
            data-target="#settingCategoryModal"
          >
            設定分類
          </button>
        </div>
      </div>
    </div>

    <div class="page-body">
      <div class="table-wrap">
        <el-table :data="productList" :key="tableKey" :header-cell-style="{fontWeight: 600}" style="width: 100%">
          <el-table-column align="center" width="70px">
            <template slot-scope="scope">
              <eva-icon
                v-if="scope.row.categorY_ID"
                class="icon"
                name="star"
                fill="#ffc107"
                width="25"
                height="25"
                data-toggle="modal"
                data-target="#chooseCategoryModal"
                @click="(productCategory = scope.row.categorY_ID), (productID = scope.row.parT_NOX)"
              />
              <eva-icon
                v-else
                class="icon"
                name="star-outline"
                fill="#ffc107"
                width="25"
                height="25"
                data-toggle="modal"
                data-target="#chooseCategoryModal"
                @click="(productCategory = scope.row.categorY_ID), (productID = scope.row.parT_NOX)"
              />
            </template>
          </el-table-column>
          <el-table-column label="商品名稱">
            <template slot-scope="scope">
              {{ scope.row.parT_NAM }}<span style="font-size: 10px">{{ scope.row.uniT_NAME }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" width="120px">
            <template slot-scope="scope">
              <eva-icon
                v-if="scope.row.shoW_PIC"
                class="icon"
                name="image"
                fill="#808080"
                width="25"
                height="25"
                @click="(productImgUrl = ''), openImage(scope.row)"
              />
            </template>
          </el-table-column>
          <el-table-column label="數量" align="center">
            <template slot-scope="scope">
              <eva-icon
                class="icon"
                name="minus-circle"
                fill="#808080"
                width="25"
                height="25"
                @click="scope.row.salE_QTY > 0 ? scope.row.salE_QTY-- : null"
              />
              <input type="text" class="num-input" v-model="scope.row.salE_QTY" />
              <eva-icon
                class="icon"
                name="plus-circle"
                fill="#808080"
                width="25"
                height="25"
                @click="scope.row.salE_QTY++"
              />
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <button
                type="button"
                class="add-btn"
                :style="
                  scope.row.choice === 'Y' ? { background: 'red' } : { background: '#606266' }
                "
                @click="addCart(scope.row)"
              >
                加入購物車
              </button>
            </template>
          </el-table-column>
          <el-table-column slot="empty">暫無數據</el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="count" @current-change="changePage" />
      </div>
    </div>

    <!--常購商品的分類 Modal -->
    <div
      class="modal fade"
      id="chooseCategoryModal"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5>請選取欲設定之分類，如要移出常購清單，請選擇『移出常購』選項</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="category-list">
              <template v-for="item in latestCategory">
                <label
                  v-if="item.iS_ACTIVE"
                  class="category"
                  :key="item.categorY_ID"
                  :for="item.categorY_ID"
                >
                  <input
                    type="radio"
                    :id="item.categorY_ID"
                    :value="item.categorY_ID"
                    v-model="productCategory"
                  />
                  {{ item.categorY_NAME }}
                </label>
              </template>
              <label class="category" for="non">
                <input type="radio" id="non" :value="null" v-model="productCategory" />
                移除常購分類
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="(productCategory = null), (productID = null)"
            >
              關閉
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="updateProductCategory"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 設定分類 Modal -->
    <div
      class="modal fade"
      id="settingCategoryModal"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5>設定分類</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="category-list">
              <div class="category" v-for="item in latestCategory" :key="item.categorY_ID">
                <span>{{ item.sort }}.</span>
                <input type="text" v-model="item.categorY_NAME" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="latestCategory = JSON.parse(JSON.stringify(originalCategory))"
            >
              關閉
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="settingCategory"
            >
              儲存
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- 圖片 Modal -->
    <div class="modal fade" id="imageModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">{{ productName }}</div>
          <div class="modal-body text-center" style="height: 640px;">
            <img v-if="hasImage" width="100%" :src="productImgUrl" @error="imageError" />
            <img v-else width="100%" src="~@/assets/img/no_food.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import {
  apiGetCategory,
  apiSettingCategory,
  apiGetFrequentlyBought,
  apiUpdateProductCategory
} from "@/apis/product";

export default {
  name: "FrequentlyBought",
  data() {
    return {
      tableKey: 0,
      // 客戶資訊
      customerInfo: {},

      // 關鍵字
      keyword: "",
      // 查詢的分類(預設全部)
      searchCategory: "",
      // 常購商品總筆數
      count: 0,
      // 常購商品列表
      productList: [],

      // 原始的分類
      originalCategory: [],
      // 更新後的分類
      latestCategory: [],

      // 常購商品編號
      productID: null,
      // 常購商品分類
      productCategory: null,

      // 商品名稱
      productName: "",
      // 商品圖片
      productImgUrl: "",
      // 商品有圖片
      hasImage: true,

      // 當前分頁
      page: 0
    };
  },

  methods: {
    // 加入購物車
    addCart(item) {
      // this.getFrequentlyBought();
      // 購物車
      let cart = [];
      if (localStorage.CART) {
        cart = JSON.parse(localStorage.CART);
      }
      let cartLength = cart.length;

      // 商品資訊
      const product = {
        docX_NOX: "", // 預設傳入空字串
        iteM_XXX: ++cartLength, // 商品項次，從1開始
        parT_NOX: item.parT_NOX, // 商品編號
        parT_NAM: item.parT_NAM, // 商品名稱
        uniT_NAME: item.uniT_NAME, // 商品單位
        salE_QTY: item.salE_QTY // 所選取商品數量
      };

      if (cart.length > 0) {
        let sameProduct = cart.findIndex(element => element.parT_NOX === product.parT_NOX);
        sameProduct >= 0 ? cart.splice(sameProduct, 1) : cart.push(product);
      } else {
        cart.push(product);
      }

      localStorage.setItem("CART", JSON.stringify(cart));
      this.$store.dispatch("getCartProductQuantity", cart.length);

      this.updateTable();
    },

    // 加入&移除分類
    updateProductCategory() {
      this.updateLoading(true);
      apiUpdateProductCategory({
        cusT_ID: this.customerInfo.cusT_ID,
        parT_NOX: this.productID,
        categorY_ID: this.productCategory
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.$notify({
              title: "成功",
              message: "修改完成",
              type: "success",
              duration: 2500
            });
            this.getFrequentlyBought();
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 設定分類
    settingCategory() {
      this.latestCategory.forEach((item, index, arr) => {
        item.categorY_NAME.indexOf("新類別") > -1
          ? (arr[index].iS_ACTIVE = false)
          : (arr[index].iS_ACTIVE = true);
      });
      this.updateLoading(true);
      apiSettingCategory(this.latestCategory)
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.$notify({
              title: "成功",
              message: "設定完成",
              type: "success",
              duration: 2500
            });
            this.getCategory();
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 點擊圖片
    openImage(product) {
      this.productName = product.parT_NAM;
      this.productImgUrl = product.imG_URL;
      this.hasImage = true;
      $("#imageModal").modal("show");
    },

    // 未找到圖片
    imageError() {
      this.hasImage = false;
    },

    // 換頁
    changePage(page) {
      this.page = --page;
      this.getFrequentlyBought();
    },

    // 取得常購商品
    getFrequentlyBought() {
      this.updateLoading(true);
      apiGetFrequentlyBought({
        cusT_NOX: this.customerInfo.cusT_NOX,
        stoR_CDX: this.customerInfo.stoR_CDX,
        keyword: this.keyword,
        pageIndex: this.page,
        category: [this.searchCategory]
      })
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.count = response.data.count;
            this.productList = response.data.datas;
            this.updateTable();
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // 更新 table
    updateTable() {
      this.productList.map(item => (item.salE_QTY = 1, item.choice = "N"));
      const choice = JSON.parse(localStorage.CART).map(item => {
        return {
          parT_NOX: item.parT_NOX,
          salE_QTY: item.salE_QTY
        };
      });
      this.productList.forEach((product, index, arr) => {
        choice.map(item => {
          if (item.parT_NOX === product.parT_NOX) {
            arr[index].choice = "Y";
            arr[index].salE_QTY = item.salE_QTY;
          }
        });
      });
      this.tableKey++;
    },

    // 取得分類
    getCategory() {
      this.updateLoading(true);
      apiGetCategory(this.customerInfo)
        .then(response => {
          if (response.status === 200) {
            this.updateLoading(false);
            this.originalCategory = response.data;
            this.latestCategory = JSON.parse(JSON.stringify(response.data));
          }
        })
        .catch(() => {
          this.updateLoading(false);
        });
    },

    // loading
    ...mapActions(["updateLoading"])
  },

  computed: {
    ...mapGetters(["updateCart"])
  },

  watch: {
    updateCart(val) {
      if (val) {
        this.updateTable();
        this.$store.commit("UPDATE_CART", false);
      }
    }
  },

  created() {
    this.customerInfo = JSON.parse(localStorage.CUSTOMER_INFO);
    this.$axios.all([this.getCategory(), this.getFrequentlyBought()]);
  },

  destroyed() {
    this.$store.commit("UPDATE_CART", false);
  }
};
</script>

<style lang="scss" scoped>
.icon {
  cursor: pointer;
}
.num-input {
  text-align: center;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-width: 1.5px;
  background: none;
}
.category-list {
  padding: 40px;
  .category {
    display: flex;
    margin-bottom: 20px;
    span {
      width: 50px;
      font-size: 1.8em;
    }
    input[type="text"] {
      background-color: #fef6ee;
      line-height: 30px;
      border-radius: 5px;
      margin-right: 10px;
      border: none;
      padding: 0 15px;
      flex: 1;
    }
    input[type="radio"] {
      background-color: #fef6ee;
      line-height: 30px;
      border-radius: 5px;
      margin-right: 10px;
      border: none;
      padding: 0 15px;
    }
  }
}

/deep/ .el-table, .el-table__expanded-cell {
  font-weight: 600;
}
</style>
